<template>
  <base-template>
    <div class="content">
      <div class="main-title">
        <h6 class="title-item">Perjanjian Pinjaman Dana</h6>
      </div>
      <!-- {{ info.loan_time }} -->
      <p class="p20">Perjanjian Pinjaman ("Perjanjian") ini ditandatangani pada tanggal {{ formatTimes (new Date().getTime(),12) }} oleh dan antara:</p>
      <p>I. Nama : Ellis Lisnawati </p>
      <p class="p20">Jabatan : Ketua Koperas </p>
      <p class="p20">Dalam hal ini mewakili Koperasi Simpan Pinjam (“KSP”) MAJU BERSAMA ABADI, selanjutnya disebut Pihak Pertama. </p>
      <p class="p20">II. Nama : {{ info.oneself_name }} </p>
      <p class="p20">No. KTP : {{ info.oneself_idcard }}</p>
      <p class="p20">selanjutnya disebut Pihak Kedua. </p>

      <p class="title-item">Pasal 1 </p>
      <p>Pihak Pertama sepakat untuk memberikan pinjaman sebesar Rp1.000.000 ("Pokok Pinjaman") kepada Pihak Kedua dengan tunduk pada syarat dan ketentuan dalam Perjanjian ini.</p>

      <p class="title-item">Pasal 2 </p>
      <p class="p20">Pihak Kedua sepakat untuk membayar biaya persiapan dan biaya administrasi kepada Pihak Pertama sebesar 3.3% dari Pokok Pinjaman yang dipotong langsung pada saat pencairan Pokok Pinjaman dan Pihak Kedua berkewajiban membayar bunga sebesar 30% dari Pokok Pinjaman per Tahun yang dihitung sejak tanggal Perjanjian ini bersamaan dengan Jumlah hari sejak tanggal Perjanjian ini dibuat dan Pinjaman pokok harus dilunasi selambat-lambatnya 92 hari</p>

      <p class="title-item">Pasal 3 </p>
      <p>Pihak Kedua wajib membayar denda sebesar 0.1% dari Pokok Pinjaman dan/atau Bunga untuk setiap hari keterlambatan pemenuhan kewajibannya dalam Pasal 2 di atas.</p>

      <p class="title-item">Pasal 4 </p>
      <p>Pihak Kedua dapat melunasi Pokok Pinjaman lebih awal sebelum tanggal yang ditentukan dalam Pasal 2 Perjanjian ini dengan membayar penalti sebesar Rp0.</p>

      <p class="title-item">Pasal 5</p>
      <p>Hal-hal di bawah ini merupakan cidera janji oleh Pihak Kedua atas Perjanjian ini:</p>
      <p>a. Pihak Kedua tidak membayar Pokok Pinjaman dan/atau Bunga sesuai Pasal 2 Perjanjian ini;</p>
      <p>b. Pihak Kedua melanggar salah satu atau sejumlah kewajibannya berdasarkan Perjanjian ini; atau</p>
      <p>c. Pihak Kedua ditaruh di bawah pengampuan.</p>
      <p>Dalam hal terjadi peristiwa cidera janji, tanpa kewajiban Pihak Pertama untuk memberi surat peringatan kepada Pihak Kedua yang mana kewajiban tersebut dengan ini dikesampingkan oleh Pihak Kedua, Pihak Kedua wajib melunasi seluruh kewajibannya yang terutang kepada Pihak Pertama secara seketika dan sekaligus.</p>
      <p>Apabila tidak dilakukan pelunasan dalam waktu 92 (Sembilan puluh dua) hingga 180 (Seratus delapan puluh) hari kalender sejak tanggal jatuh tempo, maka Pihak Kedua sepakat bahwa Pihak Pertama dapat melakukan tindakan-tindakan lainnya sesuai ketentuan hukum yang berlaku untuk memperoleh pelunasan dari Pihak Kedua, termasuk namun tidak terbatas pada, menginformasikan mengenai cidera janji oleh Pihak Kedua ini kepada otoritas yang berwenang di Indonesia dan pihak-pihak lainnya yang mana untuk tindakan tersebut, Pihak Kedua sepakat tidak akan menganggapnya sebagai pencemaran nama baik dan/atau pelanggaran hukum apapun.</p>

      <p class="title-item">Pasal 6</p>
      <!-- Pembayaran oleh Pihak Kedua kepada Pihak Pertama dilakukan ke rekening bank KSP Bina Insan Sukses Usaha, nomor rekening: , atas nama: Koperasi Simpan Pinjam Bina Insan Sukses Usaha. -->
      <p> Apabila Pihak Kedua tidak membayar kewajibannya secara penuh, pembayaran yang diterima akan dialokasikan untuk pembayaran kewajiban Pihak Kedua dengan urutan sebagai berikut: (i) biaya-biaya, (ii) denda-denda, (iii) Bunga dan (iv) Pokok Pinjaman.</p>

      <p class="title-item">Pasal 7</p>
      <p>Setiap sengketa yang timbul dari atau sehubungan dengan Perjanjian ini akan diselesaikan oleh Pihak Pertama dan Pihak Kedua melalui Badan Arbitrase Nasional Indonesia (BANI).</p>
      <p>Pihak Pertama dan Pihak Kedua telah memahami sepenuhnya isi Perjanjian ini dan telah menandatangani Perjanjian ini dalam keadaan sadar tanpa paksaan/tekanan dari pihak manapun secara elektronik sesuai ketentuan peraturan perundang-undangan yang berlaku sehingga tidak diperlukan lagi adanya tanda tangan basah.</p>
    </div>
  </base-template>
</template>

<script>
import { getLoanAgreement, getHomePage } from '@/api/axios/index'
export default {
  data() {
    return {
      repay_time: '',
      info: {
        app_name: '',
        apply_cash: '',
        bank_account: '',
        bank_name: '',
        curp_number: '',
        interest: '',
        name: '',
        penalty: '',
        repay_time: '',
        server_interest: '',
        sign_time: ''
      }
    }
  },
  created() {
    // 到期还款日计算，91天后
    const nowDate = new Date()
    nowDate.setDate(nowDate.getDate() + 91)
    const { year, month, date } = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate()
    }
    this.repay_time = `${year}-${month.toString().length > 1 ? month : '0' + month}-${date.toString().length > 1 ? date : '0' + date}`
    this.getHomePage()
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.changeStatusIsDarkFont(true)
    this.$H5Object.isShowBottomTabinter(false)
  },
  methods: {
    async getLoanAgreement(loan_cash) {
      const { data } = await getLoanAgreement({ loan_cash: loan_cash }) || {}
      this.info = data || {}
    },
    async getHomePage() {
      try {
        const { data } = await getHomePage()
        this.getLoanAgreement(data.minimum_cash)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  background: #fff;
  padding: 32px;
  line-height: 34px;
  font-size: 28px;
}
.main-title{
  text-align: center;
}
.title-item{
  font-family: 'Calibri';
  font-weight: bold;
  margin-bottom: 15px;
}
.p10{
  padding-bottom: 10px;
}
.p20{
  padding-bottom: 20px;
}
.p40{
  padding-bottom: 40px;
}
.item-content{
  padding-left: 40px;
}
table {text-align: center;}
</style>
